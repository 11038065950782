import { RouteConfig } from 'vue-router'

export const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('@/views/Empty.vue'),
    children: [
      {
        path: '',
        name: 'home',
        redirect: '',
        meta: {
          requiresAuth: true,
        },
        component: () => import('@/views/Home.vue'),
        children: [
          {
            path: '',
            name: 'landing',
            component: () => import('@/views/Landing.vue'),
          },
          {
            path: '/gallery',
            name: 'gallery',
            component: () => import('@/views/EngagementGallery.vue'),
          },
          {
            path: '/wedding-gallery',
            name: 'wedding-gallery',
            component: () => import('@/views/WeddingGallery.vue'),
          },
          {
            path: '/details',
            name: 'details',
            component: () => import('@/views/Details.vue'),
          },
          {
            path: '/wedding-party',
            name: 'wedding-party',
            component: () => import('@/views/WeddingParty.vue'),
          },
          {
            path: '/our-story',
            name: 'our-story',
            component: () => import('@/views/OurStory.vue'),
          },
          {
            path: '/things-to-do',
            name: 'things-to-do',
            component: () => import('@/views/ThingsToDo.vue'),
          },
          {
            path: '/registry',
            name: 'registry',
            component: () => import('@/views/Registry.vue'),
          },
        ],
      },
      {
        path: '/*',
        name: 'not-found',
        component: () =>
          import(/* webpackChunkName: "not-found" */ '@/views/NotFound.vue'),
      },
    ],
  },
]
