























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { toPascal, toTitle } from '@/utils/string'

@Component
export default class Icon extends Vue {
  @Prop(String)
  readonly fileName: string | undefined

  @Prop({ default: 24 })
  readonly width: string | number | undefined

  @Prop({ default: 24 })
  readonly height: string | number | undefined

  @Prop({ default: '0 0 24 24' })
  readonly viewBox: string | undefined

  @Prop({ default: false, required: false })
  readonly decorative!: boolean

  @Prop(String)
  readonly ariaLabel: string | undefined

  @Prop(String)
  readonly color: string | undefined

  icon = null

  get listeners(): Record<string, unknown> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this
    return Object.assign({}, this.$listeners, {
      click(event: PointerEvent) {
        vm.$emit('click', event)
      },
    })
  }

  get isClickable(): boolean {
    return !!(this.$listeners && this.$listeners.click)
  }

  get iconFileName(): string | undefined {
    let iconFileName: string | undefined = undefined
    if (this.$slots.default?.[0]?.text) {
      iconFileName = this.$slots.default[0].text
    } else if (this.fileName) {
      iconFileName = this.fileName
    }

    if (iconFileName && iconFileName?.length > 1) {
      iconFileName = toPascal(iconFileName)
    }

    return iconFileName
  }

  get iconTitle(): string | undefined {
    let iconTitle: string | undefined = undefined
    if (this.iconFileName) {
      iconTitle = toTitle(this.iconFileName)
    }
    return iconTitle
  }

  @Watch('iconFileName', { immediate: true })
  onModeChange(newIconFileName: string): void {
    this.icon = () => import(`@/assets/icons/${newIconFileName}.vue`)
  }

  get ariaLabelledBy(): string {
    if (this.ariaLabel) {
      return this.ariaLabel
    }

    return `${this.iconTitle} icon`
  }

  get style(): Record<string, unknown> {
    let color = 'currentColor'
    const hexRegex = new RegExp('#[0-9a-fA-F]{3,6}', 'g')
    if (this.color && hexRegex.test(this.color)) {
      color = this.color
    }
    return {
      '--color': color,
    }
  }

  get classes(): string {
    const hexRegex = new RegExp('#[0-9a-fA-F]{3,6}', 'g')

    if (this.color && !hexRegex.test(this.color)) {
      return `text-${this.color}`
    }

    return ''
  }
}
