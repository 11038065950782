import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/scss/main.scss'
// import '@/plugins/gmaps'
import '@/plugins/dayjs'
import VueTheMask from 'vue-the-mask'
import Icon from '@/components/Icon.vue'

Vue.use(VueTheMask)
Vue.component('Icon', Icon)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app')
